import moment from 'moment';
export default class NewEvent {
    id = null;
    name = null;
    starttime = null;
    startdate = null;
    endtime = null;
    enddate = null;
    location = null;


    constructor(startdate) {
        this.startdate = startdate;

        const start = moment(startdate, 'YYYY-MM-DD');

        //moment to next 15 min round up
        const now = moment();
        const diff = now.minutes() % 15
        now.subtract(diff, 'minutes')
        now.add(15, 'minutes').seconds(0)
        this.starttime = now.format('HH:mm')
        this.endtime = now.add(1, 'Hour').format('HH:mm')

        start.hours(now.hours());
        start.minutes(now.minutes());

        this.enddate = start.format('YYYY-MM-DD')
        console.log(this.startdate, this.starttime, this.enddate, this.endtime)
    }
}
