<template>
  <v-card>
    <v-toolbar
      flat
      :color="event.color"
      prominent
    >
      <v-spacer />
      <v-card-title>
        {{ event.name || 'new Event' }}
      </v-card-title>
      <v-spacer />
      <v-btn
        absolute
        dark
        fab
        bottom
        left
        small
        :disabled="isNew"
        @click="editable = !editable"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="event">
        <v-list dense>
          <v-list-item>
            <v-list-item-icon />
            <v-list-item-content>
              <v-text-field
                v-model="event.name"
                :label="event.name ? 'Title' : 'add Title'"
                :rules="[rules.required()]"
                hide-details
                :disabled="isDisabled()"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-text-field
                v-model="event.startdate"
                label="startdate"
                :rules="[rules.required()]"
                type="date"
                hide-details
                :disabled="isDisabled()"
              />
              <v-combobox
                v-model="event.starttime"
                :items="getTimeslots"
                label="starttime"
                type="time"
                hide-details
                :rules="[rules.required()]"
                :disabled="isDisabled()"
                @change="updateTimes"
                @select="updateTimes"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-text-field
                v-model="event.enddate"
                label="enddate"
                :rules="[rules.required()]"
                type="date"
                hide-details
                :disabled="isDisabled()"
              />
              <v-combobox
                v-model="event.endtime"
                :items="getTimeslots"
                label="endtime"
                type="time"
                hide-details
                :rules="[rules.required()]"
                :disabled="isDisabled()"
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-text-field
                v-model="event.location"
                label="location"
                hide-details
                :disabled="isDisabled()"
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-format-color-fill
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-select
                v-model="event.color"
                label="event color"
                hide-details
                :items="colors"
                :color="event.color"
                :rules="[rules.required()]"
                :disabled="isDisabled()"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isDashboard">
            <v-list-item-icon>
              <v-icon>
                mdi-clipboard-text
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-select
                v-model="event.project_id"
                label="project"
                hide-details
                :items="projects"
                item-value="id"
                item-text="name"
                :rules="[rules.required()]"
                :disabled="isDisabled()"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="!isNew"
        depressed
        small
        color="primary"
        @click="confirmDelete"
      >
        delete
      </v-btn>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="saveEvent"
      >
        save
      </v-btn>
      <v-btn
        depressed
        small
        color="primary"
        @click="close"
      >
        close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin';

export default {
  name: 'NewEventDialog',
  mixins: [ValidationRulesMixin],
  props: {
    isNew: Boolean,
    event: Object,
    isDashboard: Boolean,
  },
  data() {
    return {
      editable: false
    }
  },
  computed: {
    ...mapState('globals', ['colors']),
    ...mapState('project', ['projects']),
    ...mapGetters('globals', ['getTimeslots']),
  },
  mounted() {
    this.loadProjects();
  },
  methods: {
    ...mapActions('project', ['loadProjects']),

    isDisabled() {
      if (this.isNew) {
        return false;
      } else {
        return !this.editable;
      }
    },
    close() {
      this.editable = false;
      this.$emit('close')
    },
    saveEvent() {
      if (!this.$refs.event.validate()) {
        return;
      }
      this.editable = false;
      console.log('saveEvent', this.event);
      this.$emit('save', this.event)
    },
    updateTimes(time) {
      this.event.endtime = moment(this.event.startdate + ' ' + time).add(1, 'Hour').format('HH:mm')
      this.event.enddate = moment(this.event.startdate + ' ' + time).add(1, 'Hour').format('YYYY-MM-DD')
    },
    confirmDelete() {
      this.$confirm('delete event?', {
        type: 'delete'
      })
        .then(value => {
          if (value) {
            this.$emit('deleteEvent', this.event)
          }
        })

    }
  }
}
</script>

<style scoped>

</style>
